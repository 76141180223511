import React from "react";

import { AlertDialog } from "~/apps/shared/components/alert-dialog/alert-dialog";

type Props = {
  name: string;
  onClose: () => void;
  onConfirm: () => void;
};

export const UsersUserDeletionAlertDialog: React.FC<Props> = ({
  name,
  onClose,
  onConfirm,
}) => {
  return (
    <AlertDialog
      confirmLabel="Excluir"
      message={`${name} será inativado(a). Tem certeza que deseja prosseguir?`}
      onClose={() => {
        onClose();
      }}
      onConfirm={() => {
        onConfirm();
      }}
      open
      title="Inativar usuário."
    />
  );
};
