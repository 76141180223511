import * as Sentry from "@sentry/react";
import React from "react";

import { ApplicationProvider } from "~/apps/corporate/contexts/application.context";
import { LivechatProvider } from "~/apps/corporate/contexts/livechat.context";
import { UserProvider } from "~/apps/corporate/contexts/user.context";
import { ErrorWarning } from "~/apps/shared/components/error-warning/error-warning";
import * as envUtils from "~/apps/shared/utils/env";

import { Button } from "@toolkit/v2";

import { Livechat } from "../livechat/livechat";
import { styles } from "./styles";

type Props = React.HTMLAttributes<HTMLDivElement> &
  Pick<React.ComponentProps<typeof Sentry.ErrorBoundary>, "fallback"> & {
    level?: "debug" | "error" | "fatal" | "info" | "log" | "warning";
  };

export const ErrorBoundary: React.FC<Props> = ({
  children,
  fallback,
  level = "error",
  ...props
}) => {
  return (
    <Sentry.ErrorBoundary
      beforeCapture={(scope) => {
        scope.setLevel(level);

        scope.setTag("unhandled", true);

        return scope;
      }}
      fallback={
        fallback
          ? fallback
          : ({ error }) => {
              const { stack } = error;

              return (
                <>
                  <div css={styles.root} {...props}>
                    <ErrorWarning
                      message="Estamos passando por alguns ajustes. Voltaremos em breve. Caso o problema persista, por favor entre em contato com nosso suporte."
                      title="Não é você, sou eu!"
                    />
                    <div css={styles.buttons.root}>
                      {envUtils.isMockableEnv() && !!stack ? (
                        <Button
                          onClick={async () => {
                            await navigator.clipboard.writeText(stack);
                          }}
                          fill="outlined"
                        >
                          Copiar
                        </Button>
                      ) : null}
                      <Button
                        onClick={() => {
                          window.location.reload();
                        }}
                        variant="pink"
                      >
                        Recarregar
                      </Button>
                    </div>
                  </div>
                  <ErrorBoundaryLivechat />
                </>
              );
            }
      }
    >
      {children}
    </Sentry.ErrorBoundary>
  );
};

const ErrorBoundaryLivechat: React.FC = () => {
  return (
    <Sentry.ErrorBoundary
      fallback={() => {
        return <></>;
      }}
    >
      <ApplicationProvider>
        <UserProvider>
          <LivechatProvider>
            <Livechat />
          </LivechatProvider>
        </UserProvider>
      </ApplicationProvider>
    </Sentry.ErrorBoundary>
  );
};
