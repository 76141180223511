import * as Sentry from "@sentry/react";
import React, { createContext, useCallback, useState } from "react";
import { connect } from "react-redux";

import { navigate } from "@reach/router";
import userActions from "~/actions/user.action";
import { ALERT_TYPES } from "~/apps/shared/constants";
import { ERROR } from "~/apps/shared/constants/errors";
import { useContextFactory } from "~/apps/shared/hooks/use-context-factory";
import { Error } from "~/apps/shared/types";
import { logger } from "~/apps/shared/utils/logger";

import { SaveUserBossSchema } from "../components/user-profile-drawer/views/save-user-boss/save-user-boss.schema";
import { EditUserProfileDto, LoginDto } from "../dtos/user.dto";
import {
  clearUserFromLocalStorage,
  getUserFromLocalStorage,
} from "../helpers/user.helper";
import { UserModel } from "../models/user.model";
import { useApplication } from "./application.context";
import * as userService from "./user.service";

interface Actions {
  editUserProfile: (data: EditUserProfileDto) => Promise<boolean>;
  login: (
    data: LoginDto,
  ) => Promise<{
    error: Error | null;
    user: UserModel | null;
  }>;
  logout: () => Promise<void>;
  saveUserBoss: (data: SaveUserBossSchema) => Promise<boolean>;
  setUser: (user: UserModel) => void;
  trackContextOfChoiceDialogView: () => Promise<void>;
}

type State = {
  isLoadingEditUserProfile: boolean;
  isLoadingLogin: boolean;
  isLoadingLogout: boolean;
  user: UserModel | null;
};

const initialState: State = {
  isLoadingEditUserProfile: false,
  isLoadingLogin: false,
  isLoadingLogout: false,
  user: getUserFromLocalStorage(),
};

type ContextProps = Actions & State;

export const UserContext = createContext<ContextProps>({
  ...initialState,
  editUserProfile: async () => {
    return false;
  },
  login: async () => {
    return {
      error: null,
      user: null,
    };
  },
  logout: async () => {
    return;
  },
  saveUserBoss: async () => {
    return false;
  },
  setUser: () => {
    return;
  },
  trackContextOfChoiceDialogView: async () => {
    return;
  },
});

type Props = {
  editUser: typeof userActions.editUser;
  logout: typeof userActions.logout;
  setUser: typeof userActions.setUser;
};

const Provider: React.FC<Props> = ({
  children,
  editUser: editUserAction,
  logout: logoutAction,
  setUser: setUserAction,
}) => {
  const { showSnackMessage } = useApplication();

  const [state, setState] = useState(initialState);

  const editUserProfile = useCallback(
    async (data: EditUserProfileDto) => {
      const { user } = state;

      if (!user) {
        logger.error("user not found");

        return false;
      }

      setState((prev) => ({
        ...prev,
        isLoadingEditUserProfile: true,
      }));

      const editUserProfileResponse = await userService.editUserProfile(
        data,
        user.getUserToken(),
      );

      if (editUserProfileResponse.error) {
        const error = editUserProfileResponse.error;

        setState((prev) => ({
          ...prev,
          isLoadingEditUserProfile: false,
        }));

        showSnackMessage(error.description, ALERT_TYPES.ERROR);

        return false;
      }

      if (!editUserProfileResponse.data) {
        const error = ERROR.UNEXPECTED;

        setState((prev) => ({
          ...prev,
          isLoadingEditUserProfile: false,
        }));

        showSnackMessage(error.description, ALERT_TYPES.ERROR);

        return false;
      }

      setState((prev) => ({
        ...prev,
        isLoadingEditUserProfile: false,
        user: user.upsertUser(editUserProfileResponse.data!),
      }));

      // TODO: remover no futuro
      editUserAction(editUserProfileResponse.data!);

      return true;
    },
    [editUserAction, showSnackMessage, state.user],
  );

  const login = useCallback(
    async (data: LoginDto) => {
      setState((prev) => ({
        ...prev,
        isLoadingLogin: true,
      }));

      const loginResponse = await userService.login(data);

      if (loginResponse.error) {
        const error = loginResponse.error;

        setState((prev) => ({
          ...prev,
          isLoadingLogin: false,
        }));

        showSnackMessage(error.description, ALERT_TYPES.ERROR);

        return {
          error,
          user: null,
        };
      }

      if (!loginResponse.data) {
        const error = ERROR.UNEXPECTED;

        setState((prev) => ({
          ...prev,
          isLoadingLogin: false,
        }));

        showSnackMessage(error.description, ALERT_TYPES.ERROR);

        return {
          error,
          user: null,
        };
      }

      const user = loginResponse.data!;

      setState((prev) => ({
        ...prev,
        isLoadingLogin: false,
        user,
      }));

      // TODO: remover no futuro
      setUserAction({
        ...user.toObject(),
        loggedIn: true,
        loggingIn: false,
        loginError: {},
      });

      return {
        error: null,
        user,
      };
    },
    [setUserAction, showSnackMessage, state.user],
  );

  const logout = useCallback(async () => {
    setState((prev) => ({
      ...prev,
      isLoadingLogout: true,
    }));

    const logoutResponse = await userService.logout();

    if (logoutResponse.error) {
      const error = logoutResponse.error;

      showSnackMessage(error.description, ALERT_TYPES.ERROR);
    }

    setState((prev) => ({
      ...prev,
      isLoadingLogout: false,
      user: null,
    }));

    Sentry.setUser(null);

    clearUserFromLocalStorage();

    navigate("/login");

    // TODO: remover no futuro
    logoutAction();
  }, [logoutAction, showSnackMessage, state.user]);

  const saveUserBoss = useCallback(
    async (data: SaveUserBossSchema) => {
      const { user } = state;

      if (!user) {
        logger.error("user not found");

        return false;
      }

      setState((prev) => ({
        ...prev,
        isLoadingSaveUserBoss: true,
      }));

      const editUserResponse = await userService.editUser({
        ...user.toObject(),
        ...data,
      });

      if (editUserResponse.error) {
        const error = editUserResponse.error;

        setState((prev) => ({
          ...prev,
          isLoadingSaveUserBoss: false,
        }));

        showSnackMessage(error.description, ALERT_TYPES.ERROR);

        return false;
      }

      if (!editUserResponse.data) {
        const error = ERROR.UNEXPECTED;

        setState((prev) => ({
          ...prev,
          isLoadingSaveUserBoss: false,
        }));

        showSnackMessage(error.description, ALERT_TYPES.ERROR);

        return false;
      }

      setState((prev) => ({
        ...prev,
        isLoadingSaveUserBoss: false,
        user: user.upsertUser(editUserResponse.data!),
      }));

      return true;
    },
    [showSnackMessage, state.user],
  );

  const setUser = useCallback(
    (user: UserModel) => {
      setState((prev) => ({
        ...prev,
        user,
      }));

      // TODO: remover no futuro
      setUserAction(user.toObject());
    },
    [setUserAction],
  );

  const trackContextOfChoiceDialogView = useCallback(async () => {
    const { user } = state;

    if (!user) {
      return;
    }

    await userService.trackContextOfChoiceDialogView(user.getUserToken());
  }, [state.user]);

  return (
    <UserContext.Provider
      value={{
        ...state,
        editUserProfile,
        login,
        logout,
        saveUserBoss,
        setUser,
        trackContextOfChoiceDialogView,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const UserProvider = connect(null, {
  editUser: userActions.editUser,
  logout: userActions.logout,
  setUser: userActions.setUser,
})(Provider);

export const useUser = useContextFactory("UserContext", UserContext);
