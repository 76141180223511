import React, { useCallback, useRef, useState } from "react";

import { Link } from "@reach/router";
import { useUser } from "~/apps/corporate/contexts/user.context";
import { CapabilityProtectedRender } from "~/apps/shared/components/capability-protected-render/capability-protected-render";
import { Icon } from "~/apps/shared/components/icon/icon";
import { PermissionProtectedRender } from "~/apps/shared/components/permission-protected-render/permission-protected-render";
import {
  capabilities,
  CapabilitiesLiterals,
  PermissionsKeysLiterals,
} from "~/apps/shared/constants/enums";
import { useLocation } from "~/apps/shared/hooks/use-location";
import { useOnClickOutside } from "~/apps/shared/hooks/use-on-click-outside";

import { styles } from "./styles";

export const NavBarDesktopOthersMenu: React.VFC<
  React.HTMLAttributes<HTMLButtonElement>
> = ({ ...props }) => {
  const {
    location: { pathname },
  } = useLocation();

  const { user } = useUser();

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleCloseMenu = useCallback(() => {
    setIsMenuOpen(false);
  }, []);

  const handleOpenMenu = useCallback(() => {
    setIsMenuOpen(true);
  }, []);

  const links: {
    capabilities?: CapabilitiesLiterals[];
    label: string;
    links: {
      capabilities?: CapabilitiesLiterals[];
      label: string;
      permissions?: PermissionsKeysLiterals[];
      to: string;
    }[];
    to: string;
  }[] = [
    {
      capabilities: [capabilities.EXPENSES],
      label: "Despesas",
      links: [
        {
          label: "Minhas despesas",
          to: "/expenses",
        },
        {
          label: "Meus relatórios",
          to: "/expenses/reports",
        },
        {
          label: "Meus adiantamentos",
          permissions: ["expenses.advances"],
          to: "/expenses/advanced",
        },
        {
          capabilities: [capabilities.EXPENSE_FOR_OTHERS],
          label: "Relatórios de outros",
          to: "/expenses/others-reports",
        },
      ],
      to: "/expenses",
    },
    {
      label: "Eventos",
      links: [
        {
          label: "Próximos eventos",
          to: "/events",
        },
        {
          label: "Eventos em progresso",
          to: "/events",
        },
        {
          label: "Eventos passados",
          to: "/events",
        },
      ],
      to: "/events",
    },
    {
      capabilities: [capabilities.ANALYTICS, capabilities.FINANCIAL],
      label: "Gestão",
      links: [
        {
          capabilities: [capabilities.ANALYTICS],
          label: "Análise de dados",
          to: "/analytics",
        },
        {
          capabilities: [capabilities.ANALYTICS],
          label: "Download de relatórios",
          // permissions: ["analytics.excelReportDownloads"],
          to: "/analytics/report-download",
        },
        {
          label: "Viagens em tempo real",
          to: "/analytics/real-time",
        },
        {
          capabilities: [capabilities.FINANCIAL],
          label: "Controle financeiro",
          to: "/financial",
        },
      ],
      to: "/analytics",
    },
    {
      capabilities: [
        capabilities.CONFIGURATIONS,
        capabilities.EXTERNAL_CREDENTIALS,
      ],
      label: "Configurações",
      links: [
        {
          label: "Pessoas",
          to: "/configurations/users",
        },
        {
          label: "Regras de viagem",
          to: "/configurations/trips",
        },
        {
          label: "Organização da empresa",
          to: "/configurations/company/cost-centers",
        },
        {
          label: "Regras de despesas",
          to: "/configurations/expenses",
        },
        {
          label: "Configurações de conta",
          to: "/configurations/financial",
        },
        {
          label: "Integrações",
          to: "/configurations/integrations",
        },
      ],
      to:
        user && user.hasConfigurationsCapability()
          ? "/configurations/users"
          : "/configurations/developers",
    },
  ];

  const isActive = links.some((link) => {
    if (pathname === "/") {
      return false;
    }

    if (link.to.startsWith(pathname)) {
      return true;
    }

    return link.links.some((link) => {
      return link.to.startsWith(pathname);
    });
  });

  const othersMenuRef = useRef<HTMLDivElement | null>(null);

  const toggleMenu = useCallback(() => {
    if (isMenuOpen) {
      handleCloseMenu();

      return;
    }

    handleOpenMenu();
  }, [handleCloseMenu, handleOpenMenu, isMenuOpen]);

  useOnClickOutside(handleCloseMenu, othersMenuRef);

  return (
    <>
      <div css={styles.menu.overlay({ open: isMenuOpen })} />
      <div
        onMouseEnter={handleOpenMenu}
        onMouseLeave={handleCloseMenu}
        ref={othersMenuRef}
      >
        <button
          css={styles.button.root({
            active: isActive || isMenuOpen,
          })}
          onClick={toggleMenu}
          {...props}
        >
          <span>Outros</span>
          <Icon css={styles.button.icon} size={16} use="chevron-down" />
        </button>
        <div
          css={styles.menu.root({
            open: isMenuOpen,
          })}
        >
          <div css={styles.menu.container}>
            {links.map((link) =>
              link.capabilities ? (
                <CapabilityProtectedRender
                  atLeastOne
                  capabilities={link.capabilities}
                  key={link.label}
                >
                  <div css={styles.menu.section.root}>
                    <Link
                      css={styles.menu.section.label}
                      onClick={handleCloseMenu}
                      tabIndex={isMenuOpen ? 0 : -1}
                      to={link.to}
                    >
                      {link.label}
                    </Link>
                    <nav>
                      <ul css={styles.menu.links.root}>
                        {link.links.map((l) =>
                          l.permissions ? (
                            <PermissionProtectedRender
                              key={l.label}
                              permissions={l.permissions}
                            >
                              <li>
                                <Link
                                  css={styles.menu.links.link}
                                  onClick={handleCloseMenu}
                                  tabIndex={isMenuOpen ? 0 : -1}
                                  to={l.to}
                                >
                                  {l.label}
                                </Link>
                              </li>
                            </PermissionProtectedRender>
                          ) : (
                            <li key={l.label}>
                              <Link
                                css={styles.menu.links.link}
                                onClick={handleCloseMenu}
                                tabIndex={isMenuOpen ? 0 : -1}
                                to={l.to}
                              >
                                {l.label}
                              </Link>
                            </li>
                          ),
                        )}
                      </ul>
                    </nav>
                  </div>
                </CapabilityProtectedRender>
              ) : (
                <div css={styles.menu.section.root} key={link.label}>
                  <Link
                    css={styles.menu.section.label}
                    onClick={handleCloseMenu}
                    tabIndex={isMenuOpen ? 0 : -1}
                    to={link.to}
                  >
                    {link.label}
                  </Link>
                  <nav>
                    <ul css={styles.menu.links.root}>
                      {link.links.map((l) =>
                        l.permissions ? (
                          <PermissionProtectedRender
                            key={l.label}
                            permissions={l.permissions}
                          >
                            <li>
                              <Link
                                css={styles.menu.links.link}
                                onClick={handleCloseMenu}
                                tabIndex={isMenuOpen ? 0 : -1}
                                to={l.to}
                              >
                                {l.label}
                              </Link>
                            </li>
                          </PermissionProtectedRender>
                        ) : (
                          <li key={l.label}>
                            <Link
                              css={styles.menu.links.link}
                              onClick={handleCloseMenu}
                              tabIndex={isMenuOpen ? 0 : -1}
                              to={l.to}
                            >
                              {l.label}
                            </Link>
                          </li>
                        ),
                      )}
                    </ul>
                  </nav>
                </div>
              ),
            )}
          </div>
        </div>
      </div>
    </>
  );
};
