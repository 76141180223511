import React from "react";

import { Router } from "@reach/router";
import { CardsProvider } from "~/apps/corporate/contexts/cards.context";
import { ClientConfigProvider } from "~/apps/corporate/contexts/client-config.context";
import { DocumentsProvider } from "~/apps/corporate/contexts/documents.context";
import { LoyaltyProgramsProvider } from "~/apps/corporate/contexts/loyalty-program.context";
import { MemberGetMemberProvider } from "~/apps/corporate/contexts/member-get-member.context";
import { UserProvider } from "~/apps/corporate/contexts/user.context";
import { Configurations } from "~/apps/corporate/pages/configurations/configurations";
import { Error } from "~/apps/corporate/pages/error/error";
import { Login } from "~/apps/corporate/pages/login/login";
import { NewTrip } from "~/apps/corporate/pages/new-trip/new-trip";
import { OIDCCallback } from "~/apps/corporate/pages/oidc-callback/oidc-callback";
import { Password } from "~/apps/corporate/pages/password/password";
import { Permission } from "~/apps/corporate/pages/permission/permission";
import { SSOLogin } from "~/apps/corporate/pages/sso-login/sso-login";
import { Travels } from "~/apps/corporate/pages/travels/travels";
import { Trips } from "~/apps/corporate/pages/trips/trips";
import { CapabilityProtectedRoute } from "~/apps/shared/components/capability-protected-route/capability-protected-route";
import { Livechat } from "~/apps/shared/components/livechat/livechat";
import { capabilities } from "~/apps/shared/constants/enums";

import { Approvals } from "@components/approvals";
import { Events } from "@components/events";
import { ExpenseAdvanceApprovalReview } from "@components/expense-advance-approval-review";
import { Expenses } from "@components/expenses/Expenses";
import { Report as ExpenseReport } from "@components/expenses/Report/report";
import { ReportApprovalReview as ExpenseReportApprovalReview } from "@components/expenses/ReportApprovalReview/ReportApprovalReview";
import { Financial } from "@components/financial/Financial";
import { HotelBookingConfirmation } from "@components/hotel-booking-confirmation";
import { SupportEvaluation } from "@components/support-evaluation";
import { UserReviews } from "@components/user-reviews";

import { ClientProvider } from "./apps/corporate/contexts/client.context";
import { LivechatProvider } from "./apps/corporate/contexts/livechat.context";
import { Analytics } from "./apps/corporate/pages/analytics/analytics";

const OldPrivateRoutes: React.FC = () => {
  return (
    <Router primary={false}>
      <Approvals path="/approvals/*" />
      <CapabilityProtectedRoute
        capabilities={[capabilities.BASIC]}
        component={Events}
        path="/events/*"
      />
      <CapabilityProtectedRoute
        capabilities={[capabilities.EXPENSES]}
        component={Expenses}
        path="/expenses/*"
      />
      <ExpenseAdvanceApprovalReview path="/expense-advance/:expenseAdvanceToken/approval-review" />
      <ExpenseReportApprovalReview path="/reports/:reportToken/approval-review" />
      <ExpenseReport path="/reports/:reportToken" />
      <CapabilityProtectedRoute
        capabilities={[capabilities.FINANCIAL]}
        component={Financial}
        path="/financial"
      />
      <HotelBookingConfirmation path="/hotel-booking-confirmation/:hotelNegotiatedBookingRequestToken" />
      <SupportEvaluation path="/support-evaluation/:supportEvaluationToken" />
      <CapabilityProtectedRoute
        capabilities={[capabilities.REVIEWS]}
        component={UserReviews}
        path="/user-reviews/*"
      />
    </Router>
  );
};

const PrivateRoutes: React.FC = () => {
  return (
    <Router primary={false}>
      <CapabilityProtectedRoute
        atLeastOne
        capabilities={[capabilities.SEARCH, capabilities.SELF_SEARCH]}
        component={NewTrip}
        path="/"
      />
      <CapabilityProtectedRoute
        capabilities={[capabilities.ANALYTICS]}
        component={Analytics}
        path="/analytics/*"
      />
      <CapabilityProtectedRoute
        atLeastOne
        capabilities={[
          capabilities.CONFIGURATIONS,
          capabilities.EXTERNAL_CREDENTIALS,
        ]}
        component={Configurations}
        path="/configurations/*"
      />
      <CapabilityProtectedRoute
        capabilities={[capabilities.BASIC]}
        component={Error}
        path="/error"
      />
      <Travels path="/travels/*" />
      <CapabilityProtectedRoute
        capabilities={[capabilities.BASIC]}
        component={Trips}
        path="/trips"
      />
    </Router>
  );
};

const PublicRoutes: React.FC = () => {
  return (
    <Router primary={false}>
      <Login path="/login" />
      <OIDCCallback path="/oidc-callback" />
      <Password path="/password/*" />
      <Permission path="/permission" />
      <SSOLogin path="/sso-login/*" />
    </Router>
  );
};

export const Routes: React.FC = () => {
  return (
    <>
      <UserProvider>
        <ClientProvider>
          <ClientConfigProvider>
            <LivechatProvider>
              <CardsProvider>
                <DocumentsProvider>
                  <LoyaltyProgramsProvider>
                    <MemberGetMemberProvider>
                      <OldPrivateRoutes />
                      <PrivateRoutes />
                    </MemberGetMemberProvider>
                  </LoyaltyProgramsProvider>
                </DocumentsProvider>
              </CardsProvider>
              <Livechat />
            </LivechatProvider>
          </ClientConfigProvider>
        </ClientProvider>
        <PublicRoutes />
      </UserProvider>
    </>
  );
};
