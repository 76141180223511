import userService from "../apis/user.api";
import userConstants from "../constants/user.constant";

export const userActions = {
  editUser,
  logout,
  setUser,
};

export default userActions;

function editUser(user) {
  return dispatch => dispatch({ type: userConstants.EDIT_USERNAME, payload: user });
}

function logout() {
  void userService.logout();

  return dispatch => dispatch({ type: userConstants.LOGOUT });
}

function setUser(user) {
  return dispatch => dispatch({ type: userConstants.LOGIN_SUCCESS, user });
}
