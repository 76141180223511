import React from "react";

import { useLivechat } from "~/apps/corporate/contexts/livechat.context";

import { Button } from "@toolkit/v2";

import { styles } from "./styles";

export const SidebarHelpButton: React.FC = () => {
  const { openLivechat } = useLivechat();

  return (
    <div css={styles.root}>
      <span css={styles.title}>Não encontrou o que queria?</span>
      <span css={styles.text}>
        Não se preocupe! Temos uma equipe de especialistas pronto para te
        atender.
      </span>
      <Button
        css={styles.button}
        fill="outlined"
        onClick={() => {
          openLivechat();
        }}
      >
        Falar com um especialista
      </Button>
    </div>
  );
};
