import * as agencyApi from "~/apps/corporate/apis/agency.api";
import { ERROR } from "~/apps/shared/constants/errors";
import { Error } from "~/apps/shared/types";
import { Either, failure, success } from "~/apps/shared/utils/either";
import { formatServiceError } from "~/apps/shared/utils/errors";

import { ChatConfig } from "../models/agency.model";

export const getChatConfig = async (
  agencyToken: string,
): Promise<Either<Error, ChatConfig>> => {
  try {
    return success(await agencyApi.getChatConfig(agencyToken));
  } catch (error) {
    return failure(
      formatServiceError({
        error,
        fallback: ERROR.UNEXPECTED,
      }),
    );
  }
};
