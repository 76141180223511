import React from "react";

import { useLivechat } from "~/apps/corporate/contexts/livechat.context";

import { styles } from "./styles";

export const NavBarDesktopHelpButton: React.FC = () => {
  const { openLivechat } = useLivechat();

  return (
    <button
      css={styles.root}
      onClick={() => {
        openLivechat();
      }}
    >
      Precisa de ajuda?
    </button>
  );
};
