import React, { createContext, useCallback, useEffect, useState } from "react";

import { ALERT_TYPES } from "~/apps/shared/constants";
import { ChatProvider } from "~/apps/shared/constants/enums";
import { useContextFactory } from "~/apps/shared/hooks/use-context-factory";
import { Error } from "~/apps/shared/types";

import { ChatConfig } from "../models/agency.model";
import { useApplication } from "./application.context";
import * as livechatService from "./livechat.service";
import { useUser } from "./user.context";

interface Actions {
  openLivechat(): void;
}

type State = {
  chatConfig: ChatConfig | null;
  errorOnFetch: Error | null;
  isLoading: boolean;
};

const initialState: State = {
  chatConfig: null,
  errorOnFetch: null,
  isLoading: false,
};

type ContextProps = Actions & State;

const LivechatContext = createContext<ContextProps>({
  ...initialState,
  openLivechat: () => {
    return;
  },
});

export const LivechatProvider: React.FC = ({ children }) => {
  const { showSnackMessage } = useApplication();
  const { user } = useUser();

  const [state, setState] = useState(initialState);

  const fetchLivechat = useCallback(async () => {
    if (!user) {
      return;
    }

    const agencyToken = user.getAgencyToken();

    if (!agencyToken) {
      return;
    }

    setState((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    const getChatConfigResponse = await livechatService.getChatConfig(
      agencyToken,
    );

    if (getChatConfigResponse.isFailure()) {
      const error = getChatConfigResponse.data;

      setState((prevState) => ({
        ...prevState,
        errorOnFetch: error,
        isLoading: false,
      }));

      showSnackMessage(error.description, ALERT_TYPES.ERROR);

      return;
    }

    setState((prevState) => ({
      ...prevState,
      chatConfig: getChatConfigResponse.data,
      isLoading: false,
    }));
  }, [showSnackMessage, user]);

  const openJivoChat = useCallback(() => {
    const api = window.jivo_api;

    if (!api) {
      return;
    }

    api.open();
  }, []);

  const openOctadeskChat = useCallback(() => {
    const api = window.octadesk.chat;

    if (!api) {
      return;
    }

    api.show();
    api.showApp();
    api.hideButton();
  }, []);

  const openLivechat = useCallback(() => {
    const { chatConfig } = state;

    if (!chatConfig) {
      return;
    }

    switch (chatConfig.provider) {
      case ChatProvider.JIVO: {
        openJivoChat();

        return;
      }
      case ChatProvider.OCTADESK: {
        openOctadeskChat();

        return;
      }
      default:
        return;
    }
  }, [openJivoChat, openOctadeskChat, state.chatConfig]);

  useEffect(() => {
    void fetchLivechat();
  }, [fetchLivechat]);

  return (
    <LivechatContext.Provider
      value={{
        ...state,
        openLivechat,
      }}
    >
      {children}
    </LivechatContext.Provider>
  );
};

export const useLivechat = useContextFactory(
  "LivechatContext",
  LivechatContext,
);
