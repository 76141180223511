import React, { useContext, useEffect } from "react";

import { Link, LinkGetProps, Router } from "@reach/router";
import { CapabilityProtectedRender } from "~/apps/shared/components/capability-protected-render/capability-protected-render";
import { CapabilityProtectedRoute } from "~/apps/shared/components/capability-protected-route/capability-protected-route";
import { PermissionProtectedRender } from "~/apps/shared/components/permission-protected-render/permission-protected-render";
import { PermissionProtectedRoute } from "~/apps/shared/components/permission-protected-route/permission-protected-route";
import cx from "classnames";
import { css } from "emotion";

import { MEDIA_QUERIES } from "@constants";

import { defaultTheme } from "@theme";

import { Divider } from "@shared/Divider";
import Layout from "@shared/Layout";
import SnackBar from "@shared/SnackBar";
import SpinnerPortal from "@shared/Spinner";

import { AdvancedExpenses } from "./AdvancedExpenses";
import { ExpensesProvider, ExpensesContext } from "./Expenses.context";
import { ExpensesList } from "./ExpensesList/ExpensesList";
import { OthersReportsList } from "./OthersReportsList/OthersReportsList";
import { ReportsList } from "./ReportsList/ReportsList";

const styles = {
  container: css({
    width: "100%",
    paddingRight: "16px",
    paddingLeft: "16px",
    marginRight: "auto",
    marginLeft: "auto",
    ["@media (min-width: 1200px)"]: {
      maxWidth: 1250,
    },
  }),
  root: css({
    padding: "2rem 0 3.5rem",
  }),
  title: css({
    fontSize: 24,
    color: defaultTheme.textColor,
    fontWeight: "bold",
    paddingBottom: "2rem",
    [MEDIA_QUERIES.desktopBreakpoint]: {
      paddingTop: ".5rem",
      paddingBottom: "2.5rem",
    },
  }),
  activeLink: css({
    color: defaultTheme.textColor,
    textDecoration: "none",
    cursor: "pointer",
    fontWeight: "bold",
    borderBottom: `2px solid ${defaultTheme.primaryColor}`,
  }),
  inactiveLink: css({
    color: defaultTheme.subTextColor,
    transition: "all 0.3s ease",
    textDecoration: "none",
    cursor: "pointer",
    ["&:hover"]: {
      color: defaultTheme.primaryColor,
    },
  }),
  link: css({
    fontSize: 14,
    padding: "1rem 0",
    marginRight: "1.75rem",
    transition: "color 0.2s ease 0s",
    [MEDIA_QUERIES.mobileBreakpoint]: {
      fontSize: 15,
    },
  }),
  menuRow: css({
    paddingBottom: "2rem",
    whiteSpace: "nowrap",
    overflowX: "auto",
    WebkitOverflowScrolling: "touch",
    msOverflowStyle: "-ms-autohiding-scrollbar",
    ["&::-webkit-scrollbar"]: {
      display: "none",
    },
  }),
};

const RouteLink = (props: { to: string; children: string }): JSX.Element => {
  const getProps = (inprops: LinkGetProps) => {
    return {
      className: cx(
        styles.link,
        inprops.isCurrent ? styles.activeLink : styles.inactiveLink,
      ),
    };
  };

  return <Link {...props} getProps={getProps} />;
};

const Expenses = () => {
  const {
    isLoading,
    snack,
    handleSnackClose,
    checkUserPermisions,
  } = useContext(ExpensesContext);

  useEffect(() => {
    checkUserPermisions();
  }, []);

  return (
    <Layout>
      <SpinnerPortal visible={isLoading} />
      <SnackBar
        visible={snack.open}
        type={snack.type}
        message={snack.message}
        handleClose={handleSnackClose}
      />
      <div className={styles.container}>
        <div className={styles.root}>
          <div className={styles.title}>Despesas</div>
          <div className={styles.menuRow}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <RouteLink to="">Minhas despesas</RouteLink>
              <RouteLink to="reports">Meus relatórios</RouteLink>
              <PermissionProtectedRender permissions={["expenses.advances"]}>
                <RouteLink to="advanced">Meus adiantamentos</RouteLink>
              </PermissionProtectedRender>
              <CapabilityProtectedRender capabilities={["expenseForOthers"]}>
                <RouteLink to="others-reports">Relatórios de outros</RouteLink>
              </CapabilityProtectedRender>
            </div>
            <Divider style={{ marginBottom: ".5rem" }} />
          </div>
          <div>
            <Router primary={false}>
              <ExpensesList path="/" />
              <PermissionProtectedRoute
                component={AdvancedExpenses}
                path="/advanced"
                permissions={["expenses.advances"]}
              />
              <ReportsList path="/reports" />
              <CapabilityProtectedRoute
                capabilities={["expenseForOthers"]}
                component={OthersReportsList}
                path="/others-reports"
              />
            </Router>
          </div>
        </div>
      </div>
    </Layout>
  );
};

const Container = () => (
  <ExpensesProvider>
    <Expenses />
  </ExpensesProvider>
);

export { Container as Expenses };
