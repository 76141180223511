import React from "react";

import { useLivechat } from "~/apps/corporate/contexts/livechat.context";

import { ChatProvider } from "../../constants/enums";
import { LivechatJivoChat } from "./livechat-jivo-chat/livechat-jivo-chat";
import { LivechatOctadeskChat } from "./livechat-octadesk-chat/livechat-octadesk-chat";

export const Livechat: React.FC = () => {
  const { chatConfig, errorOnFetch, isLoading } = useLivechat();

  if (!chatConfig || errorOnFetch || isLoading) {
    return null;
  }

  switch (chatConfig.provider) {
    case ChatProvider.JIVO:
      return <LivechatJivoChat chatConfig={chatConfig} />;
    case ChatProvider.OCTADESK:
      return <LivechatOctadeskChat chatConfig={chatConfig} />;
    default:
      return null;
  }
};
